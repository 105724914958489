<template>
    <div>
        <cabecera>
            <div class="contenedor hero hero--catalogo">
                <h1>Catálogo de Productos</h1>
                <div class="categorias">
                    <router-link v-for="categoria in categorias" :key="categoria.id" :to="categoria.ruta" class="categoria" :class="'categoria--' + categoria.id">
                        <h2 class="categoria__nombre" :class="'categoria__nombre--' + categoria.id">{{ categoria.nombre }}</h2>
                        <div class="categoria__fondo" :class="'categoria__fondo--' + categoria.id">
                            <picture>
                                <source :srcset="categoria.ruta_imagen_avif" type="image/avif">
                                <source :srcset="categoria.ruta_imagen_webp" type="image/webp">
                                <source :srcset="categoria.ruta_imagen_jpg" type="image/jpg">
                                <img class="categoria__imagen" :src="categoria.ruta_imagen_jpg" :alt="'Imagen de la categoría de ' + categoria.nombre">
                            </picture>
                        </div>
                    </router-link>
                </div>
            </div>
        </cabecera>
        <div class="catalogo contenedor">
            <router-view v-slot="{Component}">
                <transition name="fade" mode="out-in">
                    <component :is="Component" :key="$route.path"/>
                </transition>
            </router-view>
        </div>
        <pie></pie>
        <contacto-flotante/>
    </div>
</template>
<script>
import cabecera from '@/components/header.vue';
import pie from '@/components/footer.vue';
import dataSource from '@/data.json';
export default {
    components: {cabecera, pie},
    data() {
        return {
            categorias: dataSource.categorias
        }
    }
}
</script>
<style lang="css">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
</style>